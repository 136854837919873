import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/style/index.scss";
import "animate.css";
import VueAnimateOnScroll from "vue-animate-onscroll";
Vue.use(VueAnimateOnScroll);
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
require("dayjs/locale/zh-cn");
dayjs.locale("zh-cn");
import Paginate from "vuejs-paginate";
Vue.component("v-paginate", Paginate);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import scroll from "vue-seamless-scroll";
Vue.use(scroll, { componentName: "scroll-seamless" });
import "./assets/iconfont/iconfont.css";
// 防止vue原型上的属性被修改
Object.defineProperties(Vue.prototype, {
  $cloneDeep: {
    get() {
      return cloneDeep;
    },
  },
  $debounce: {
    get() {
      return debounce;
    },
  },
  $dayjs: {
    get() {
      return dayjs;
    },
  },
});
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
