import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import("../views/home/index");
const ShareholderIntroduction = () =>
  import("../views/shareholder-introduction/index");
const ProductService = () => import("../views/product-service/index");
const PartyBuildingCulture = () =>
  import("../views/party-building-culture/index");
const AboutUs = () => import("../views/about-us/index");
const NewsDetails = () => import("../views/news-details/index");
const News = () => import("../views/news/index");
const notFound = () => import("../views/404");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/shareholder-introduction",
    name: "ShareholderIntroduction",
    component: ShareholderIntroduction,
  },
  {
    path: "/product-service",
    name: "ProductService",
    component: ProductService,
  },
  {
    path: "/party-building-culture",
    name: "PartyBuildingCulture",
    component: PartyBuildingCulture,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/news-details",
    name: "NewsDetails",
    component: NewsDetails,
  },
  {
    path: "*",
    name: "notFound",
    component: notFound,
  },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes,
  linkActiveClass: "active-menu-item",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    console.log("正在滾動");
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
